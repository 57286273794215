import shiftSummaryReportApi from '@/api/shiftSummaryReport.api';
import i18n from '@/i18n';
import moment from 'moment';

export default {
  name: 'ShiftSummaryDialogComponent',
  model: {
    prop: 'model',
    event: 'change'
  },
  props: {
    model: {
      type: Object,
      required: true,
      date: null,
      selectedShift: null
    },
    fleetMap: {
      type: Map,
      required: false,
      default: new Map()
    },
    categoryMap: {
      type: Map,
      required: false,
      default: new Map()
    },
    thingsMap: {
      type: Map,
      required: false,
      default: new Map()
    }
  },
  components: {
  },
  data: () => ({
    validForm: false,
    menuDate: false,
    loadingSpinner: false,
    todayDate: new moment().format('YYYY-MM-DD'),
    validationRules: {
      required: (value) => value != null || `${i18n.t('required')}`,
      noFuture: (value) => {
        if (value != null) {
          const inputDate = new moment(value, 'DD/MM/YYYY')
          const currentDate = new moment().startOf('day')

          if (inputDate.isAfter(currentDate)) {
            return `${i18n.t('selector.dateAndTime.futureDate')}`;
          }
        }
        return true;
      }
    },
    workShifts: [],
    workShiftDuration: 0,
    workShiftsData: undefined
  }),
  computed: {
    formattedDate() {
      return this.formatDate(this.model.date)
    }
  },
  mounted() {
    this.getShiftOptions()
  },
  created() {
  },
  methods: {
    onDateChange() {
      this.menuDate = false;
    },
    formatDate(date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },

    getShiftOptions() {
      this.workShiftsData = JSON.parse(localStorage.getItem('enterpriseTimeSpan'))
      if (!this.workShiftsData || !this.workShiftsData.start || !this.workShiftsData.shift) {
        return;
      }
      const timezone = Number(localStorage.getItem("timezone") || '-3')
      const startEnterpriseTime = this.workShiftsData.start + timezone;
      this.workShiftDuration = 24 / this.workShiftsData.shift
      for (let shift = 0; shift < this.workShiftsData.shift; shift++) {
        const start = ((startEnterpriseTime + (shift * this.workShiftDuration)) % 24).toString().padStart(2, '0') + ':00'
        const end = ((startEnterpriseTime + ((shift + 1) * this.workShiftDuration)) % 24).toString().padStart(2, '0') + ':00'
        this.workShifts.push({
          number: shift,
          start: start,
          end: end,
          label: start + ' - ' + end
        })
      }
    },

    cancel() {
      this.$emit('closeDialog')
      this.resetFields()
    },
    async getReport() {
      this.loadingSpinner = true
      const timezone = Number(localStorage.getItem("timezone") || '-3')
      const startEnterpriseTime = this.workShiftsData.start + timezone;
      const selectedShiftData = this.workShifts.find(s => s.number === this.model.selectedShift)
      const sinceDate = new moment(this.model.date).add((startEnterpriseTime + (selectedShiftData.number * this.workShiftDuration)), 'hours')
      const toDate = new moment(sinceDate).add(this.workShiftDuration, 'hours')
      await shiftSummaryReportApi.getReport(sinceDate.unix(), toDate.unix(), moment(this.model.date).unix(), this.model.selectedShift);

      // if (report) {
      //   report.fileName = `${new moment(this.model.date).format('DD_MM')} - ${i18n.t(SHIFTS_KEYS[this.model.selectedShift])}`;
      //   await this.generateExcelDocument(report, {});
      // }

      this.loadingSpinner = false
      this.$emit('getShiftSummaryReport')
      this.resetFields()
    },
    resetFields() {
      this.model.date = null
      this.model.selectedShift = null
    },
    generateExcelDocument() {
      // Crea un nuevo libro de trabajo
      // shiftSummaryService.generateExcelReport(
      //   reportData,
      //   this.categoryMap,
      //   this.fleetMap,
      //   this.thingsMap
      // );
    }
  }
}
