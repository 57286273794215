import service from '@/middleware'
import store from '@/store/store'

const shiftSummaryReportApi = {
  async getReport(from, to, dateSelected, selectedShift) {
    const firebase = store.getters['messaging/getFirebaseToken'];
    const body = {
      from,
      to,
      firebaseToken: firebase,
      selectedShift: selectedShift,
      dateSelected: dateSelected
    }
    const { data } = await service.http.post('/api/smart-truck-dispatcher-reports/shift-summary-report', body)
    return data;
  }
}

export default shiftSummaryReportApi
